import * as bootstrap from "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import ReactModal from "react-modal";

export default (function () {
  // ------------------------------------------------------
  // @Popover
  // ------------------------------------------------------

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  // ------------------------------------------------------
  // @Tooltips
  // ------------------------------------------------------

  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  document.addEventListener("DOMContentLoaded", function () {
    ReactModal.setAppElement("#body");
  });


  document.addEventListener("DOMContentLoaded", function () {
    window.setTimeout(() => {
      document.querySelectorAll(".jx-hide-floating").forEach((e) => {
        e.classList.add("d-none");
      });
    }, 5000);
  });

})();
